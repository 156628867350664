<template>
    <v-row class="pt-5">
        <top-nav title="Mensajes"></top-nav>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
                <div v-if="q && filteredContactForms.length === 0" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                    <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                    <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
                </div>
                <div v-else-if="q" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                    <span>Mostrando {{ filteredContactForms.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
                </div>
                <v-list three-line class="mt-5" v-show="!loading">
                    <template v-for="(cf, i) in filteredContactForms">
                        <v-list-item :to="`/secure/messages/${cf.id}`" :key="cf.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ cf.name }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <div>{{ cf.email }}</div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <div>{{ (new Date(cf.dateCreated)).toLocaleDateString() }}</div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <div>{{ cf.message }}</div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="i < filteredContactForms.length - 1" :key="cf.id + '-divider'"></v-divider>
                    </template>
                </v-list>
            </v-col>
        </v-row>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando mensajes</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopNav from '@/components/admin/TopNav'

export default {
    data:() => ({ 
        q: '', loading: true 
    }),
    computed: {
        ...mapGetters({ 
            user: 'auth/user', 
            contactForms: 'contactForm/contactForms' 
        }),
        filteredContactForms() {
            return !this.q 
                ? this.contactForms.sort( this.compare )
                : this.contactForms.filter((cf) => {
                    return cf.name.toLowerCase().includes(this.q.toLowerCase())
                    || cf.email.toLowerCase().includes(this.q.toLowerCase())
                    || cf.message.toLowerCase().includes(this.q.toLowerCase())
                    || cf.dateCreated.toLowerCase().includes(this.q.toLowerCase())
                }).sort( this.compare );
        },
        resultText() {
            return this.filteredContactForms.length === 1 ? 'resultado' : 'resultados'
        },
    },
    async mounted() {
        await this.getContactForms()
        this.loading = false
    },
    methods: {
        ...mapActions({ getContactForms: 'contactForm/getContactForms' }),
        compare(a, b) {
            const key = 'dateCreated'
            // const isASC = [0, 2].includes(this.sortBy)

            // if (isASC) {
                // return a[key] < b[key] ? - 1 : Number(a[key] > b[key])
            // } else {
                return a[key] > b[key] ? - 1 : Number(a[key] < b[key])
            // }
        },
    },
    components: { TopNav }
}
</script>